import Navbar from '../../module/common/Navbar.vue'
import Share from '../../module/common/Share.vue'
import Loading from '../../module/common/Loading.vue'
import { RadioGroup, Radio, Tag, Icon, Tabs, Tab, Stepper } from 'vant'
import '../../../assets/icon/font-icon.js'
export default {
    components: {
        Navbar: Navbar,
        Share: Share,
        Loading: Loading,
        [RadioGroup.name]: RadioGroup,
        [Radio.name]: Radio,
        [Tag.name]: Tag,
        [Icon.name]: Icon,
        [Tabs.name]: Tabs,
        [Tab.name]: Tab,
        [Stepper.name]: Stepper
    },
    data () {
        return {
            loadingShow: false,
            comboRadio: 0,
            payRadio: 'wechat',
            payOrderCode: '',
            amount: 0,
            modeActive: 'CMB',
            comboList: [],
            combo: {},
            paidList: [],
            count: 365
        }
    },
    mounted () {
        this.$refs.share.default('seller')
        this.retrieveComboList()
        this.retrievePaidList()
    },
    methods: {
        comboChange (val) {
            this.combo = this.comboList[val]
            this.amount = this.combo.priceNew
        },
        async retrieveComboList () {
            // var appKey = window.sessionStorage.getItem(this.SESSION_APP)
            var pd = { appKey: 'WPP_MOC', group: 'WORK_BUSINESS' }
            const { data: res } = await this.$http.post(this.UMS_URL + '/system/combo/retrieveAppGroupComboList', this.$qs.stringify(pd))
            if (res.status === '200') {
                this.comboList = res.data
                this.combo = this.comboList[0]
                this.amount = this.combo.priceNew
            }
        },
        async retrievePaidList () {
            // var appKey = window.sessionStorage.getItem(this.SESSION_APP)
            var pd = { appKey: 'WPP_MOC', paidKeys: 'WORK_MATCH,WORK_VIEW,WORK_APPLY' }
            const { data: res } = await this.$http.post(this.UMS_URL + '/system/paid/retrieveAppCusPaidPriceList', this.$qs.stringify(pd))
            if (res.status === '200') {
                res.data.forEach(element => {
                    element.count = element.step
                    this.paidList.push(element)
                })
            }
        },
        modeChange (val) {
            if (val === 'CMB') {
                this.amount = this.combo.priceNew
            } else if (val === 'CUS') {
                this.cusCalAmount()
            }
            this.modeActive = val
        },

        cusCalAmount () {
            var amount = 0
            this.paidList.forEach(element => {
                amount += element.count * element.priceNew
            })
            this.amount = amount
        },
        async payment () {
            this.loadingShow = true
            var serial = window.sessionStorage.getItem(this.SESSION_SERIAL)
            var pd = {
                appKey: 'WPP_MOC',
                amount: this.amount,
                attach: '',
                description: '工作帮付费服务购买',
                body: '工作帮付费服务购买',
                detail: '',
                business: '',
                openid: serial
            }
            const { data: res } = await this.$http.post(this.UMS_URL + '/system/pay/createWJsapiPay', this.$qs.stringify(pd))
            if (res.status === '200') {
                var that = this
                this.payOrderCode = res.data.code
                var param = res.data.param
                window.WeixinJSBridge.invoke(
                    'getBrandWCPayRequest', param,
                    function (rs) {
                        if (rs.err_msg === 'get_brand_wcpay_request:ok') {
                            that.retrievePayStatus()
                        } else {
                            that.loadingShow = false
                        }
                    })
            }
        },
        async retrievePayStatus () {
            var pd = { appid: 'wxa8145a74e5f2b39a', code: this.payOrderCode }
            const { data: res } = await this.$http.post(this.UMS_URL + '/system/pay/retrievePayStatus', this.$qs.stringify(pd))
            if (res.status === '200') {
                this.$dialog.alert({
                    title: '提示信息',
                    message: '支付成功'
                })
                this.buyPaid()
            } else {
                this.$dialog.alert({
                    title: '提示信息',
                    message: '支付失败'
                })
            }
            this.loadingShow = false
        },
        async buyPaid () {
            var paids = this.buildPaids()
            var pd = { appKey: 'WPP_MOC', paids: paids }
            const { data: res } = await this.$http.post(this.UMS_URL + '/customer/paid/buyPaid', this.$qs.stringify(pd))
            if (res.status === '200') {
                this.$router.go(-1)
            }
        },
        buildPaids () {
            var paids = []
            if (this.modeActive === 'CMB') {
                this.combo.comboPaid.forEach(element => {
                    paids.push({ code: element.code, count: element.count })
                })
            } else if (this.modeActive === 'CUS') {
                this.paidList.forEach(element => {
                    paids.push({ code: element.code, count: element.count })
                })
            }
            return JSON.stringify(paids)
        }
    }
}
